.featured-carousel.carousel.slide, .listing-carousel.carousel.slide {
    min-height: 390px;
}
.images-carousel.carousel.slide {
    min-height: 260px;
}
.featured-carousel .carousel-indicators, .listing-carousel .carousel-indicators, .images-carousel .carousel-indicators{
    margin-bottom: 0px;
}

.featured-carousel a, .listing-carousel a, .images-carousel a {
    text-decoration: none; 
    color: initial;
}
.listing-carousel .carousel-control-prev-icon, .listing-carousel .carousel-control-next-icon, .images-carousel .carousel-control-prev-icon, .images-carousel .carousel-control-next-icon {
    background-color: rgba(0, 0, 0, 0.5);
    border: 2px solid #fff;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    padding: 20px;   
}

.listing-carousel .carousel-control-prev-icon, .images-carousel .carousel-control-prev-icon {
    background-position: .125rem;
    background-size: 80%;
}
.listing-carousel .carousel-control-next-icon, .images-carousel .carousel-control-next-icon {
    background-position: .35rem;
    background-size: 80%;
 }
.loading-wrapper {
    position: relative;
    width: 100%;
    height: 220px;
    margin: 0px auto;
    text-align: center;
    cursor: wait;
}
 .loading-ellipsis {
    display: inline-block;
    width: 80px;
    height: 80px;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);    
  }
  .loading-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #6c757d;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .loading-ellipsis div:nth-child(1) {
    left: 8px;
    animation: loading-ellipsis1 0.6s infinite;
  }
  .loading-ellipsis div:nth-child(2) {
    left: 8px;
    animation: loading-ellipsis2 0.6s infinite;
  }
  .loading-ellipsis div:nth-child(3) {
    left: 32px;
    animation: loading-ellipsis2 0.6s infinite;
  }
  .loading-ellipsis div:nth-child(4) {
    left: 56px;
    animation: loading-ellipsis3 0.6s infinite;
  }
  @keyframes loading-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes loading-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  @keyframes loading-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }  

  .bg-trans-white .carousel-indicators button {
    background-color: #000;;
  }