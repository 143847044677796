// .vertical-carousel.carousel .carousel-inner {
//     height: 100%;
//   }
//   .vertical-carousel.carousel .carousel-inner > .item {
//     transition: .6s ease-in-out top;
//   }
//   @media all and (transform-3d) {
//     .vertical-carousel.carousel .carousel-inner > .item {
//       transition: transform .6s ease-in-out;
//       backface-visibility: hidden;
//       perspective: 1000;
//     }
//   .vertical-carousel.carousel .carousel-inner > .item.next,
//   .vertical-carousel.carousel .carousel-inner > .item.active.right {
//       top: 0;
//       transform: translate3d(0, 100%, 0);
//     }
//   .vertical-carousel.carousel .carousel-inner > .item.prev,
//   .vertical-carousel.carousel .carousel-inner > .item.active.left {
//       top: 0;
//       transform: translate3d(0, -100%, 0);
//     }
//   .vertical-carousel.carousel .carousel-inner > .item.next.left,
//   .vertical-carousel.carousel .carousel-inner > .item.prev.right,
//   .vertical-carousel.carousel .carousel-inner > .item.active {
//       top: 0;
//       transform: translate3d(0, 0, 0);
//     }
//   }
//   .vertical-carousel.carousel .carousel-inner > .active {
//     top: 0;
//   }
//   .vertical-carousel.carousel .carousel-inner > .next,
//   .vertical-carousel.carousel .carousel-inner > .prev {
//     top: 0;
//     height: 100%;
//     width: 100%;
//   }
//   .vertical-carousel.carousel .carousel-inner > .next {
//     left: 0;
//     top: 100%;
//   }
//   .vertical-carousel.carousel .carousel-inner > .prev {
//     left: 0;
//     top: -100%
//   }
//   .vertical-carousel.carousel .carousel-inner > .next.left,
//   .vertical-carousel.carousel .carousel-inner > .prev.right {
//     top: 0;
//   }
//   .vertical-carousel.carousel .carousel-inner > .active.left {
//     left: 0;
//     top: -100%;
//   }
//   .vertical-carousel.carousel .carousel-inner > .active.right {
//     left: 0;
//     top: 100%;
//   }

//   .vertical-carousel.carousel .carousel-indicators {
//     right: 20px;
//     top: 50%;
//     transform: translate(-50%);
//     bottom: auto;
//     left: auto;
//     width: auto;
//     margin: 0;
//     padding: 0;
// }
// .vertical-carousel.carousel .carousel-indicators li {
//     display: block;
//     margin: 5px 0;
// }
.vertical-carousel.carousel .carousel-item {
    overflow: hidden;
}

.vertical-carousel.carousel .carousel-item-next:not(.carousel-item-start),
.vertical-carousel.carousel .active.carousel-item-end {
    transform: translateY(100%);
}

.vertical-carousel.carousel .carousel-item-prev:not(.carousel-item-end),
.vertical-carousel.carousel .active.carousel-item-start {
    transform: translateY(-100%);
}

.vertical-carousel.carousel .carousel-indicators {
    top: 0;
    left: unset;

    margin-left: unset !important;
    margin-right: 1rem;

    flex-direction: column;
}

.vertical-carousel.carousel .carousel-indicators [data-bs-target] {
    height: 30px;
    width: 3px;
}

.vertical-carousel.carousel .carousel-control-prev,
.vertical-carousel.carousel .carousel-control-next {
    width: 100%;
    height: 60px;
}

.vertical-carousel.carousel .carousel-control-prev-icon,
.vertical-carousel.carousel .carousel-control-next-icon {
    transform: rotateZ(90deg);
}

.vertical-carousel.carousel .carousel-control-next {
    top: unset;
    bottom: 0;
}