@font-face {
  font-family: 'Scriptina';
  src: url('../fonts/SCRIPTIN.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


$orange-color: #f5821f;
$blue-color: #19469d;

html{
  min-width: 360px;
  position: initial;
}
body {
  padding-bottom: 20px;
  background-image: url('../images/grey-back.jpg');
  line-height: 1.5;
}
@import 'bootstrap';
@import 'bootstrap-icons';
#container{
  position: fixed; 
  right: 0px;
  top: 0px;
  left: 0px;
  bottom: 0px;
  z-index: 0;
  text-align: center;
}

#root{
  position: absolute;
  z-index: 100;
  width: 100%;
}
a {
  color: $orange-color;
}
a:hover, a:focus {
  color: $blue-color;
}

.btn-sm .button-icon {
  width: 12px;
  height: 12px;
  display: inline-flex;
}

.social-icon {
  width: 20px;
  height: 20px;
  display: inline-flex;
  margin: 0 5px;
}

.facebook-logo {
	background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 310 310' style='enable-background:new 0 0 310 310%3B' xml:space='preserve'%3E%3Cg id='XMLID_834_'%3E%3Cpath style='fill: rgb(255 255 255)%3B' id='XMLID_835_' d='M81.703 165.106h33.981V305c0 2.762 2.238 5 5 5h57.616c2.762 0 5-2.238 5-5V165.765h39.064c2.54 0 4.677-1.906 4.967-4.429l5.933-51.502c0.163-1.417-0.286-2.836-1.234-3.899c-0.949-1.064-2.307-1.673-3.732-1.673h-44.996V71.978c0-9.732 5.24-14.667 15.576-14.667c1.473 0 29.42 0 29.42 0c2.762 0 5-2.239 5-5V5.037c0-2.762-2.238-5-5-5h-40.545C187.467 0.023 186.832 0 185.896 0c-7.035 0-31.488 1.381-50.804 19.151c-21.402 19.692-18.427 43.27-17.716 47.358v37.752H81.703c-2.762 0-5 2.238-5 5v50.844C76.703 162.867 78.941 165.106 81.703 165.106z'/%3E%3C/g%3E%3C/svg%3E");
}
.social-icon.facebook-logo:hover {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 310 310' style='enable-background:new 0 0 310 310%3B' xml:space='preserve'%3E%3Cg id='XMLID_834_'%3E%3Cpath style='fill: rgb(245 130 31)%3B' id='XMLID_835_' d='M81.703 165.106h33.981V305c0 2.762 2.238 5 5 5h57.616c2.762 0 5-2.238 5-5V165.765h39.064c2.54 0 4.677-1.906 4.967-4.429l5.933-51.502c0.163-1.417-0.286-2.836-1.234-3.899c-0.949-1.064-2.307-1.673-3.732-1.673h-44.996V71.978c0-9.732 5.24-14.667 15.576-14.667c1.473 0 29.42 0 29.42 0c2.762 0 5-2.239 5-5V5.037c0-2.762-2.238-5-5-5h-40.545C187.467 0.023 186.832 0 185.896 0c-7.035 0-31.488 1.381-50.804 19.151c-21.402 19.692-18.427 43.27-17.716 47.358v37.752H81.703c-2.762 0-5 2.238-5 5v50.844C76.703 162.867 78.941 165.106 81.703 165.106z'/%3E%3C/g%3E%3C/svg%3E");
}

.instagram-logo {
	background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 169.063 169.063' style='enable-background:new 0 0 169.063 169.063%3B' xml:space='preserve'%3E%3Cg%3E%3Cpath style='fill: rgb(255 255 255)%3B' d='M122.406 0H46.654C20.929 0 0 20.93 0 46.655v75.752c0 25.726 20.929 46.655 46.654 46.655h75.752c25.727 0 46.656-20.93 46.656-46.655V46.655C169.063 20.93 148.133 0 122.406 0z M154.063 122.407c0 17.455-14.201 31.655-31.656 31.655H46.654C29.2 154.063 15 139.862 15 122.407V46.655C15 29.201 29.2 15 46.654 15h75.752c17.455 0 31.656 14.201 31.656 31.655V122.407z'/%3E%3Cpath style='fill: rgb(255 255 255)%3B' d='M84.531 40.97c-24.021 0-43.563 19.542-43.563 43.563c0 24.02 19.542 43.561 43.563 43.561s43.563-19.541 43.563-43.561C128.094 60.512 108.552 40.97 84.531 40.97z M84.531 113.093c-15.749 0-28.563-12.812-28.563-28.561c0-15.75 12.813-28.563 28.563-28.563s28.563 12.813 28.563 28.563C113.094 100.281 100.28 113.093 84.531 113.093z'/%3E%3Cpath style='fill: rgb(255 255 255)%3B' d='M129.921 28.251c-2.89 0-5.729 1.17-7.77 3.22c-2.051 2.04-3.23 4.88-3.23 7.78c0 2.891 1.18 5.73 3.23 7.78c2.04 2.04 4.88 3.22 7.77 3.22c2.9 0 5.73-1.18 7.78-3.22c2.05-2.05 3.22-4.89 3.22-7.78c0-2.9-1.17-5.74-3.22-7.78C135.661 29.421 132.821 28.251 129.921 28.251z'/%3E%3C/g%3E%3C/svg%3E");
}
.social-icon.instagram-logo:hover  {
	background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 169.063 169.063' style='enable-background:new 0 0 169.063 169.063%3B' xml:space='preserve'%3E%3Cg%3E%3Cpath style='fill: rgb(245 130 31)%3B' d='M122.406 0H46.654C20.929 0 0 20.93 0 46.655v75.752c0 25.726 20.929 46.655 46.654 46.655h75.752c25.727 0 46.656-20.93 46.656-46.655V46.655C169.063 20.93 148.133 0 122.406 0z M154.063 122.407c0 17.455-14.201 31.655-31.656 31.655H46.654C29.2 154.063 15 139.862 15 122.407V46.655C15 29.201 29.2 15 46.654 15h75.752c17.455 0 31.656 14.201 31.656 31.655V122.407z'/%3E%3Cpath style='fill: rgb(245 130 31)%3B' d='M84.531 40.97c-24.021 0-43.563 19.542-43.563 43.563c0 24.02 19.542 43.561 43.563 43.561s43.563-19.541 43.563-43.561C128.094 60.512 108.552 40.97 84.531 40.97z M84.531 113.093c-15.749 0-28.563-12.812-28.563-28.561c0-15.75 12.813-28.563 28.563-28.563s28.563 12.813 28.563 28.563C113.094 100.281 100.28 113.093 84.531 113.093z'/%3E%3Cpath style='fill: rgb(245 130 31)%3B' d='M129.921 28.251c-2.89 0-5.729 1.17-7.77 3.22c-2.051 2.04-3.23 4.88-3.23 7.78c0 2.891 1.18 5.73 3.23 7.78c2.04 2.04 4.88 3.22 7.77 3.22c2.9 0 5.73-1.18 7.78-3.22c2.05-2.05 3.22-4.89 3.22-7.78c0-2.9-1.17-5.74-3.22-7.78C135.661 29.421 132.821 28.251 129.921 28.251z'/%3E%3C/g%3E%3C/svg%3E");
}

.linkedin-logo {
	background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 310 310' style='enable-background:new 0 0 310 310%3B' xml:space='preserve'%3E%3Cg id='XMLID_801_'%3E%3Cpath style='fill: rgb(255 255 255)%3B' id='XMLID_802_' d='M72.16 99.73H9.927c-2.762 0-5 2.239-5 5v199.928c0 2.762 2.238 5 5 5H72.16c2.762 0 5-2.238 5-5V104.73C77.16 101.969 74.922 99.73 72.16 99.73z'/%3E%3Cpath style='fill: rgb(255 255 255)%3B' id='XMLID_803_' d='M41.066 0.341C18.422 0.341 0 18.743 0 41.362C0 63.991 18.422 82.4 41.066 82.4c22.626 0 41.033-18.41 41.033-41.038C82.1 18.743 63.692 0.341 41.066 0.341z'/%3E%3Cpath style='fill: rgb(255 255 255)%3B' id='XMLID_804_' d='M230.454 94.761c-24.995 0-43.472 10.745-54.679 22.954V104.73c0-2.761-2.238-5-5-5h-59.599c-2.762 0-5 2.239-5 5v199.928c0 2.762 2.238 5 5 5h62.097c2.762 0 5-2.238 5-5v-98.918c0-33.333 9.054-46.319 32.29-46.319c25.306 0 27.317 20.818 27.317 48.034v97.204c0 2.762 2.238 5 5 5H305c2.762 0 5-2.238 5-5V194.995C310 145.43 300.549 94.761 230.454 94.761z'/%3E%3C/g%3E%3C/svg%3E");
}
.social-icon.linkedin-logo:hover {
	background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 310 310' style='enable-background:new 0 0 310 310%3B' xml:space='preserve'%3E%3Cg id='XMLID_801_'%3E%3Cpath style='fill: rgb(245 130 31)%3B' id='XMLID_802_' d='M72.16 99.73H9.927c-2.762 0-5 2.239-5 5v199.928c0 2.762 2.238 5 5 5H72.16c2.762 0 5-2.238 5-5V104.73C77.16 101.969 74.922 99.73 72.16 99.73z'/%3E%3Cpath style='fill: rgb(245 130 31)%3B' id='XMLID_803_' d='M41.066 0.341C18.422 0.341 0 18.743 0 41.362C0 63.991 18.422 82.4 41.066 82.4c22.626 0 41.033-18.41 41.033-41.038C82.1 18.743 63.692 0.341 41.066 0.341z'/%3E%3Cpath style='fill: rgb(245 130 31)%3B' id='XMLID_804_' d='M230.454 94.761c-24.995 0-43.472 10.745-54.679 22.954V104.73c0-2.761-2.238-5-5-5h-59.599c-2.762 0-5 2.239-5 5v199.928c0 2.762 2.238 5 5 5h62.097c2.762 0 5-2.238 5-5v-98.918c0-33.333 9.054-46.319 32.29-46.319c25.306 0 27.317 20.818 27.317 48.034v97.204c0 2.762 2.238 5 5 5H305c2.762 0 5-2.238 5-5V194.995C310 145.43 300.549 94.761 230.454 94.761z'/%3E%3C/g%3E%3C/svg%3E");
}

.twitter-logo {
	background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 310 310' style='enable-background:new 0 0 310 310%3B' xml:space='preserve'%3E%3Cg id='XMLID_826_'%3E%3Cpath style='fill: rgb(255 255 255)%3B' id='XMLID_827_' d='M302.973 57.388c-4.87 2.16-9.877 3.983-14.993 5.463c6.057-6.85 10.675-14.91 13.494-23.73c0.632-1.977-0.023-4.141-1.648-5.434c-1.623-1.294-3.878-1.449-5.665-0.39c-10.865 6.444-22.587 11.075-34.878 13.783c-12.381-12.098-29.197-18.983-46.581-18.983c-36.695 0-66.549 29.853-66.549 66.547c0 2.89 0.183 5.764 0.545 8.598C101.163 99.244 58.83 76.863 29.76 41.204c-1.036-1.271-2.632-1.956-4.266-1.825c-1.635 0.128-3.104 1.05-3.93 2.467c-5.896 10.117-9.013 21.688-9.013 33.461c0 16.035 5.725 31.249 15.838 43.137c-3.075-1.065-6.059-2.396-8.907-3.977c-1.529-0.851-3.395-0.838-4.914 0.033c-1.52 0.871-2.473 2.473-2.513 4.224c-0.007 0.295-0.007 0.59-0.007 0.889c0 23.935 12.882 45.484 32.577 57.229c-1.692-0.169-3.383-0.414-5.063-0.735c-1.732-0.331-3.513 0.276-4.681 1.597c-1.17 1.32-1.557 3.16-1.018 4.84c7.29 22.76 26.059 39.501 48.749 44.605c-18.819 11.787-40.34 17.961-62.932 17.961c-4.714 0-9.455-0.277-14.095-0.826c-2.305-0.274-4.509 1.087-5.294 3.279c-0.785 2.193 0.047 4.638 2.008 5.895c29.023 18.609 62.582 28.445 97.047 28.445c67.754 0 110.139-31.95 133.764-58.753c29.46-33.421 46.356-77.658 46.356-121.367c0-1.826-0.028-3.67-0.084-5.508c11.623-8.757 21.63-19.355 29.773-31.536c1.237-1.85 1.103-4.295-0.33-5.998C307.394 57.037 305.009 56.486 302.973 57.388z'/%3E%3C/g%3E%3C/svg%3E");
}
.social-icon.twitter-logo:hover {
	background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 310 310' style='enable-background:new 0 0 310 310%3B' xml:space='preserve'%3E%3Cg id='XMLID_826_'%3E%3Cpath style='fill: rgb(245 130 31)%3B' id='XMLID_827_' d='M302.973 57.388c-4.87 2.16-9.877 3.983-14.993 5.463c6.057-6.85 10.675-14.91 13.494-23.73c0.632-1.977-0.023-4.141-1.648-5.434c-1.623-1.294-3.878-1.449-5.665-0.39c-10.865 6.444-22.587 11.075-34.878 13.783c-12.381-12.098-29.197-18.983-46.581-18.983c-36.695 0-66.549 29.853-66.549 66.547c0 2.89 0.183 5.764 0.545 8.598C101.163 99.244 58.83 76.863 29.76 41.204c-1.036-1.271-2.632-1.956-4.266-1.825c-1.635 0.128-3.104 1.05-3.93 2.467c-5.896 10.117-9.013 21.688-9.013 33.461c0 16.035 5.725 31.249 15.838 43.137c-3.075-1.065-6.059-2.396-8.907-3.977c-1.529-0.851-3.395-0.838-4.914 0.033c-1.52 0.871-2.473 2.473-2.513 4.224c-0.007 0.295-0.007 0.59-0.007 0.889c0 23.935 12.882 45.484 32.577 57.229c-1.692-0.169-3.383-0.414-5.063-0.735c-1.732-0.331-3.513 0.276-4.681 1.597c-1.17 1.32-1.557 3.16-1.018 4.84c7.29 22.76 26.059 39.501 48.749 44.605c-18.819 11.787-40.34 17.961-62.932 17.961c-4.714 0-9.455-0.277-14.095-0.826c-2.305-0.274-4.509 1.087-5.294 3.279c-0.785 2.193 0.047 4.638 2.008 5.895c29.023 18.609 62.582 28.445 97.047 28.445c67.754 0 110.139-31.95 133.764-58.753c29.46-33.421 46.356-77.658 46.356-121.367c0-1.826-0.028-3.67-0.084-5.508c11.623-8.757 21.63-19.355 29.773-31.536c1.237-1.85 1.103-4.295-0.33-5.998C307.394 57.037 305.009 56.486 302.973 57.388z'/%3E%3C/g%3E%3C/svg%3E");
}

.youtube-logo {
	background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 310 310' style='enable-background:new 0 0 310 310%3B' xml:space='preserve'%3E%3Cg id='XMLID_822_'%3E%3Cpath style='fill: rgb(255 255 255)%3B' id='XMLID_823_' d='M297.917 64.645c-11.19-13.302-31.85-18.728-71.306-18.728H83.386c-40.359 0-61.369 5.776-72.517 19.938C0 79.663 0 100.008 0 128.166v53.669c0 54.551 12.896 82.248 83.386 82.248h143.226c34.216 0 53.176-4.788 65.442-16.527C304.633 235.518 310 215.863 310 181.835v-53.669C310 98.471 309.159 78.006 297.917 64.645z M199.021 162.41l-65.038 33.991c-1.454 0.76-3.044 1.137-4.632 1.137c-1.798 0-3.592-0.484-5.181-1.446c-2.992-1.813-4.819-5.056-4.819-8.554v-67.764c0-3.492 1.822-6.732 4.808-8.546c2.987-1.814 6.702-1.938 9.801-0.328l65.038 33.772c3.309 1.718 5.387 5.134 5.392 8.861C204.394 157.263 202.325 160.684 199.021 162.41z'/%3E%3C/g%3E%3C/svg%3E");
}
.social-icon.youtube-logo:hover {
	background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 310 310' style='enable-background:new 0 0 310 310%3B' xml:space='preserve'%3E%3Cg id='XMLID_822_'%3E%3Cpath style='fill: rgb(245 130 31)%3B' id='XMLID_823_' d='M297.917 64.645c-11.19-13.302-31.85-18.728-71.306-18.728H83.386c-40.359 0-61.369 5.776-72.517 19.938C0 79.663 0 100.008 0 128.166v53.669c0 54.551 12.896 82.248 83.386 82.248h143.226c34.216 0 53.176-4.788 65.442-16.527C304.633 235.518 310 215.863 310 181.835v-53.669C310 98.471 309.159 78.006 297.917 64.645z M199.021 162.41l-65.038 33.991c-1.454 0.76-3.044 1.137-4.632 1.137c-1.798 0-3.592-0.484-5.181-1.446c-2.992-1.813-4.819-5.056-4.819-8.554v-67.764c0-3.492 1.822-6.732 4.808-8.546c2.987-1.814 6.702-1.938 9.801-0.328l65.038 33.772c3.309 1.718 5.387 5.134 5.392 8.861C204.394 157.263 202.325 160.684 199.021 162.41z'/%3E%3C/g%3E%3C/svg%3E");
}

.realtor-logo {
	background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 331.18 331.18'%3E%3Cpath style='fill: rgb(255 255 255)%3B' d='M0 0V331.18H288V0ZM153.12 39.74h32.1c29.62 3.46 51.1 30.08 50.85 63-.26 32.62-21.51 58.49-50.85 61.93l-32.1 0ZM122 289.52H40.79V39.35H122Zm31.13.38V165.44L265.72 289.9Z' /%3E%3C/svg%3E");
}
.equalhousing-logo {
  background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 994.81 994.81'%3E%3Cg%3E%3Cpath style='fill: rgb(255 255 255)%3B' d='M329.31 733.11V591.87h343V733.11Z' transform='translate(-2.59 -69.44)'/%3E%3Cpath style='fill: rgb(255 255 255)%3B' d='M671.11 428.12V569.41H328.56V428.12Z' transform='translate(-2.59 -69.44)'/%3E%3Cpath style='fill: rgb(255 255 255)%3B' d='M997.41 403.22c-2.92.83-1.73 3 0 0Z' transform='translate(-2.59 -69.44)'/%3E%3Cpath style='fill: rgb(255 255 255)%3B' d='M997.41 403.22 506.59 71.34c-3.6-2.44-9.48-2.55-13-.22q-50.25 33.59-100.49 67.18C370 153.78 347 169.38 323.88 184.89S277.43 216 254.21 231.57Q218.15 255.75 182.1 280q-34.83 23.34-69.67 46.67-36.08 24.19-72.12 48.42Q21.42 387.78 2.59 400.58V534.4h65V928.94c3.23.26 6.17.71 9.11.71q155.61.06 311.2.05 270.6.33 541.2.86h3.83V535.28h64.48V403.22C995.68 406.27 994.49 404.05 997.41 403.22ZM825.74 792.37v2.51H176.23c-.07-1.34-.2-2.61-.2-3.87q0-164.19-.05-328.36c0-2.89.86-4.48 3.43-6.19 31-20.56 61.83-41.34 92.71-62q32.17-21.59 64.37-43.15t64.36-43.18q33-22.26 66-44.63c9.76-6.59 19.6-13.07 29.28-19.77 2.26-1.56 3.84-1.38 6 .08q37.27 25.08 74.65 50Q659.48 349 742.19 404.11q40.37 27 80.67 54c1.65 1.1 3 2.29 3 5Q825.66 627.76 825.74 792.37Z' transform='translate(-2.59 -69.44)'/%3E%3C/g%3E%3C/svg%3E");
}
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


.bg-trans-black  {
  background-color: rgba(0,0,0,.85) !important;
}

.bg-trans-white  {
  background-color: rgba(255,255,255,.85) !important;
}

.bg-trans-orange  {
  background-color: transparentize($orange-color, .15) !important;
}

.bg-trans-blue  {
  background-color: transparentize($blue-color, .15) !important;
}

.indent {
  text-indent: 1em;
}

.btn-orange {
  @include button-variant($orange-color, darken($orange-color, 3%), #fff);
}
// .btn-orange:after{
//   vertical-align: -2.5px;
//   font-family: 'bootstrap-icons';
//   content: '\F231';
// }
.btn-orange:focus, .btn-orange:hover, .btn-orange:active, .btn-orange:visited
{
  color:#fff;
}

.btn-blue {
  @include button-variant($blue-color, darken($blue-color, 3%), #fff);
}
// .btn-blue:after{
//   vertical-align: -2.5px;
//   font-family: 'bootstrap-icons';
//   content: '\F231';
// }
.btn-blue:focus, .btn-blue:hover, .btn-blue:active, .btn-blue:visited
{
  color:#fff;
}

.blue-color {
  color: $blue-color;
}
.orange-color {
  color: $orange-color;
}

.orange-color a {
  color: $orange-color;
}

.orange-color:focus, .orange-color:hover, .orange-color:active, .orange-color:visited
{
  color:darken($orange-color, 3%);
}

button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}
.res-modal-title {
  margin-top: -20px;
  font-size: 16pt;
  font-weight: bolder;
  font-family: 'Scriptina', var(--bs-body-font-family);
  color: #a67d34 !important;
}
.m-badge {
  margin-left: 10px;;
}

@media (max-width: 280px) {
  #root{
    width: 128vw;
  }
}
