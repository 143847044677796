$orange-color: #f5821f;
$blue-color: #19469d;

.exp-nav {
  border-bottom: 5px solid $orange-color;
}

.exp-nav .navbar-brand {
  display: none;
}
.exp-nav .brand-home, .exp-nav a.brand-home {
  margin-top: -10px;
  font-size: 16pt;
  display: inline-block;
  font-weight: bolder;
  font-family: 'Scriptina', var(--bs-body-font-family);
  color: #a67d34 !important;
}  
.exp-nav a.brand-home:hover, .exp-nav a.brand-home:focus { 
  color: rgba(0, 0, 0, 0.9) !important;
}
.navbar-brand sub {
  font-size: 6pt;
}

.navbar-brand span {
  display: block;
}

.exp-nav .broker-logo {
  background-color: #ffffff;
  border: 1px solid $orange-color;
  width: 162px;
  height: 90px;  
  padding: 2px;
  position: absolute;
  right: 24px;
  top: 10px;
}

.exp-nav .dropdown-menu {
  border: 1px solid $orange-color;
  border-radius: 0;
}

.exp-nav.navbar-light .navbar-nav .nav-link, .exp-nav.navbar-light .navbar-nav .show>.nav-link {
  color: $blue-color;
 }

.exp-nav.navbar-light .navbar-nav .nav-link.active, .exp-nav.navbar-light .navbar-nav .show>.nav-link, .exp-nav.navbar-light .navbar-nav .nav-link:focus, .exp-nav.navbar-light .navbar-nav .nav-link:hover {
 color: $orange-color;
}

.exp-nav .dropdown-item, .exp-nav .dropdown-item {
  color: $blue-color;
}

.exp-nav .dropdown-item.active, .exp-nav .dropdown-item:active {
    background-color: $orange-color;
}
.exp-nav .dropdown-item.active:focus, .exp-nav .dropdown-item.active:hover {
  // background-color: #0d6efd;
  color: $blue-color;
  background-color: $orange-color;
}
.exp-nav .dropdown-item:focus, .exp-nav .dropdown-item:hover {
  color: $orange-color;
  background-color: #ffffff;
}
.exp-nav :focus-visible {
  outline: $orange-color auto 1px;
}
.exp-nav.navbar-light .navbar-toggler {
 color: $orange-color;
}
.exp-nav hr {
  color: $orange-color;
}
@media (max-width: 767px) {
  .exp-nav .container {
    height: 60px;
    justify-content: flex-start;
  }
.navbar-nav .brand-home {
    display: none !important;
  }
  .exp-nav .navbar-brand {
    // display: initial;
    // position: absolute;
    // left: 80px;
    display: inline-block;
    font-weight: bolder;
    font-size: 20pt;
    padding-left: 10px;
    margin-top: -10px;
    font-family: 'Scriptina', var(--bs-body-font-family);
    color: #a67d34;
  }  
  .exp-nav .broker-logo {
    width: 80px;
    height: 80px;
    right: 12px;
    top: 12px;
  }   
.exp-nav #basic-navbar-nav {
  position: absolute;
  left: 0px;  
  top: 81px;
  width: 100%;
  padding: 5px 10px;
  background-color: #ffffff;
}
.exp-nav #basic-navbar-nav .me-auto .nav-link {
  padding: 5px;
}
}

@media (max-width: 360px) {
  .exp-nav .navbar-brand {
    font-size: 18pt;
  }  
}