/* common */
.ribbon-link:focus-visible {
 span {
    outline: 2px solid #000;
 }
}
.ribbon-link:hover {
    span marquee{
       text-decoration: underline;
    }
}
.ribbon {
    width: 160px;
    height: 160px;
    overflow: hidden;
    position: absolute;
  }
  .ribbon::before,
  .ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #19469d;
  }
  .ribbon span {
    position: absolute;
    display: block;
    width: 240px;
    padding: 10px 0px;
    background-color: #699abd;
    box-shadow: 0 1px 1px rgba(0,0,0,.1);
    color: #fff;
    font-size: 16px;
    line-height: 14px;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    text-transform: uppercase;
  }
  
  /* top left*/
  .ribbon-top-left {
    top: -10px;
    left: -10px;
  }
  .ribbon-top-left::before,
  .ribbon-top-left::after {
    border-top-color: transparent;
    border-left-color: transparent;
  }
  .ribbon-top-left::before {
    top: 0;
    right: 4px;
  }
  .ribbon-top-left::after {
    bottom: 4px;
    left: 0;
  }
  .ribbon-top-left span {
    left: -56px;
    top: 30px;
    transform: rotate(-45deg);
  }
  
  .card-price {
      display: inline-block;
      width: 240px;
      height: 68px;
      background-color: #f5821f;
      position: relative;
      color: white;
      margin-left: -20px;
  }
  .card-price big {
      float: left;
      font-weight: 600;
      font-size: 48px;
      line-height: 30px;
      padding: 16px 10px 0 10px;
  }
  .card-price small {
      display: block;
      clear: both;
      font-size: 12px;
      margin: 0 auto;
      text-align: center;
  }
  .card-price span {
      float: right;
      font-weight: 300;
      font-size: 22px;
      line-height: 20px;
      padding: 10px 0 0 0;
  
  }
  /* Makes the triangle */
  .card-price:before {
      content: "";
      position: absolute;
      display: block;
      right: -20px;
      width: 0;
      height: 0;
      border-top: 34px solid transparent;
      border-bottom: 34px solid transparent;
      border-left: 20px solid #f5821f;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    .card-price {
        width: 160px;
    }
    .card-price big {
        padding: 16px 0 0 0;
        font-weight: 500;
        font-size: 36px;
        line-height: 24px;        
    }        
    .card-price span {
        font-weight: 200;
        font-size: 16px;
        line-height: 12px;
        padding: 16px 0 0 0;
    }    
    .card-price small {
        font-size: 10px;
    }    
  }
  @media (min-width: 992px) and (max-width: 1200px) {
    .card-price {
        width: 220px;
    }
    .card-price big {
        padding: 16px 0 0 0;
    }    
  }
  .notepad {
      background-image: url('../images/hth/notepad.png');
      background-repeat: no-repeat;
      background-size: contain;
      padding: 50px 20px 0px 10px;
      font-size: 12px;
      max-width: 200px;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    .notepad {
        font-size: 8px;
    }
  }  

  .boxed {
      border-color: #f5821f;
      border-radius: 6px;
      -webkit-box-shadow: 0px 2px 5px 3px rgba(0,0,0,0.4);
      -moz-box-shadow: 0px 2px 5px 3px rgba(0,0,0,0.4);
      box-shadow: 0px 2px 5px 3px rgba(0,0,0,0.4);
  }

