.header-card {
    margin-top: 50px;
}
.header-card .card-body {
    width: 85%;
    margin: 0px auto 97px auto;
}
.header-team-image {
    width: 80%;
    margin: -50px auto;
}

.clhms-logo {
    position: absolute;
    right: 24px;
    bottom: 24px;
  }

@media (max-width: 767px) {
    .header-card .card-body {
        margin: 30px auto 97px auto;
    }
    .clhms-logo {
        width: 100px;
        height: 100px;
    }
}